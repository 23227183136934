.url {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.url__link {
  margin-left: 0.2rem;
  font-size: 0.8rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* Apply different styles for smaller screens */
@media screen and (max-width: 600px) {
  .url__link {
    max-width: 200px; /* Adjust the max-width for smaller screens */
    white-space: nowrap;
  }
}

/* Apply different styles for larger screens */
@media screen and (min-width: 601px) {
  .url__link {
    /* Wrap the text if its longer as 200px */
  }
}
