.parser {
  padding: 0 1rem;
}

.parser__btnSend {
  /* big */
  font-size: 1.2rem;
  padding: 0.5rem;
}

.parser__input {
  display: flex;
}

.parser__result {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  min-height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* Animated three dots like a loading indicator */
.parser__processing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.parser__processing::after {
  content: "Laden";
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    content: "Laden";
  }
  33% {
    content: "Laden.";
  }
  66% {
    content: "Laden..";
  }
  100% {
    content: "Laden...";
  }
}

/* red line left bottom the screen, position absolute, with text "Fehler" inside */
.parser__error {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5rem;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parser__error::after {
  content: "Fehler";
}
