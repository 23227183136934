.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title__pageName {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
  color: #333;
}

.title__version {
  text-align: center;
  font-size: 0.9rem;
  margin: 0;
  margin-left: 1rem;
  font-weight: 600;
  color: gray;
}
