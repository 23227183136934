.priceHistory__previousPrices {
  display: flex;
}

.priceHistory__previousPrice {
  font-size: 0.8rem;

  color: #3b3b3b;
  font-weight: 600;
  text-decoration: line-through;
  padding: 0.2rem 0.5rem;
  background-color: rgba(175, 182, 185, 0.79);
  border-radius: 5px;
  margin-right: 0.5rem;
  &:before {
    content: "(";
  }

  &:after {
    content: ")";
  }
}
