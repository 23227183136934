.navbar {
  padding-bottom: 0.6rem;
  /* Stick to the bottom */
  margin-top: auto;
  padding-top: 0.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* 1rem between lines */
}

.navbar__button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #9b9b9b;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 15px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1),
    rgba(204, 204, 204, 1)
  );

  margin-right: 1rem;
  margin-top: 0.8rem;
  position: relative;
}

.navbar__buttonCount {
  /* Red circle inside navbar__button */
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  background-color: rgb(189, 5, 5);
  color: white;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Shadow */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.navbar__button:hover {
  cursor: pointer;
  box-shadow: 0 0 rgba(0, 0, 0, 0);
  color: rgb(86, 75, 75);
}

/* On click */
.navbar__button:active {
  box-shadow: 0 0 rgba(0, 0, 0, 0);
  transform: translateY(2px);
}

/* Prevent select text */
.navbar__button::selection {
  background: transparent;
}
