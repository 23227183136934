.brandAndModel {
  display: flex;
  align-items: center;
}

.brand {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: whitesmoke;
  background-color: rgba(49, 69, 159, 0.79);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}

.model {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: whitesmoke;
  background-color: rgba(49, 69, 159, 0.79);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}

.modelError {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: whitesmoke;
  background-color: #ab1137d6;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
}
