.tile__car {
  display: flex;
  flex-direction: column;
  background-color: #d6d6d68d;
  padding: 10px 20px;
  margin: 10px 15px;
}

.tile__car--duplicate {
  display: flex;
  flex-direction: column;
  background-color: #d6d6d68d;
  padding: 10px 20px;
  margin: 10px 15px;
  border-left: 5px solid rgb(196, 4, 4);
  position: relative;
}

.tile__car--duplicate::before {
  content: "Duplicate";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(196, 4, 4);
  color: #ffffff;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.tile__firstLine {
  display: flex;
  font-size: 1rem;
}

.tile__firstLineLeft {
  margin: 0;
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: start;
}

.tile__firstLineRight {
  margin-left: auto;
  text-align: end;
}

.tile__firstLineRight > button {
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  width: 6rem;
}

.tile_newCarState {
  display: inline-block;
  background-color: #0c61ff82;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-right: 0.5rem;
  color: #ffffff;
  margin-right: 0.5rem;
}

.tile_carAccident {
  display: inline-block;
  background-color: #ab1137d6;
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-right: 0.5rem;
  color: #ffffff;
  margin-right: 0.5rem;
}

.tile__carImage {
  display: flex;
}

.tile__urls {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.tile__carPlatform {
  /* Making a image the same size as a div */
  height: 25px;
  display: flex;
  padding-top: 0.4rem;
  /* border: 1px solid #333; */
}

.tile__carPlatform > img {
  height: 100%;
}

.tile__carPlatform > button {
  margin-right: 0.5rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.tile__comparePrices {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tile__carCurrentPrice {
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  background-color: rgba(115, 191, 79, 0.79);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}

.tile__comparePrice {
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  background-color: rgb(241, 150, 31);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}

.tile__pricesAndEnginepower {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tile__pricesAndEnginepower > div {
  margin-top: 0.2rem;
}

.car__enginePowers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  margin-left: 1rem;
}

.car__enginePower {
  display: flex;
}

.car__enginePowerValue {
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: rgb(105, 105, 105);
  border-radius: 5px;
  margin-left: 0.5rem;
  color: #ffffff;
}

.emginePower__horse {
  font-size: 1.5rem;
}

.countDownWithRating {
  display: flex;
  align-items: center;
}

.tile__jasonView {
  margin-top: 1rem;
}

/* Apply different styles for smaller screens */
@media screen and (max-width: 600px) {
  .tile__car {
    display: flex;
    flex-direction: column;
    background-color: #d6d6d68d;
    padding: 10px;
    margin: 10px;
  }

  .tile__firstLineLeft {
    font-size: 0.9rem;
  }

  .tile__firstLineRight {
    font-weight: 500;
    font-size: 0.8rem;
  }

  .tile__firstLineRight > button {
    margin-right: 0;
  }
}
