.carImage__img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  font-size: small;
}

.carImage__noImage {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
}
