.statistic__row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.statistic {
  min-width: 200px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;
  color: #565656;
}

.statistic__right {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding-right: 2rem;
}
