/* Price rating */

.car__priceRating {
    margin-top: 0.5rem;
    margin-bottom: 0.3em;
    display: inline-block;
  }
  
  .indicator {
    display: flex;
    align-items: center;
  }
  
  .indicator span {
    width: 20px;
    height: 11px;
    margin: 0 1.5px;
    background-color: gray;
    border-radius: 20%;
  }

  .indicator__infoIcon{
    color: gray;
    margin-left: 0.5rem;
    cursor: pointer;
  }
  
  .indicator--WITHOUT_RATING span {
    background-color: lightgray;
  }
  
  .indicator--VERY_GOOD_PRICE span {
    background-color: #287d0e;
  }
  
  .indicator--GOOD_PRICE span {
    background-color: #52a11a;
  }
  
  .indicator--REASONABLE_PRICE span {
    background-color: #80bd23;
  }
  
  .indicator--INCREASED_PRICE span {
    background-color: #dea506;
  }
  
  .indicator--HIGH_PRICE span {
    background-color: #e7882c;
  }
  
  .car__priceRating--label{
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Gibson,Tahoma,Arial,sans-serif
  }