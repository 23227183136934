.dataflow {
  padding: 0 1rem;
}

.dataflow__input {
  display: flex;
}

.dataflow__input button {
  width: fit-content;
}

.dataflow__input input {
  font-size: 1.2rem;
  font-weight: 600;
}

.dataflow__description {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
}
/* Background color of dataflow__description blue */
.dataflow__description {
  background-color: #e6f2ff;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.dataflow__loading {
  /* font-size: 1.5rem; */
  font-weight: 900;
  padding: 0;
  margin: 0;
}

.dataflow__loading::after {
  /* Animatet three dots to simuate loading */
  content: "";
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: "....";
  }
}
