.requests {
  padding: 0 1rem;
}

.requests__input {
  display: flex;
  margin-bottom: 1rem;
}

.requests__input button {
  width: fit-content;
}

.requests__input input {
  font-size: 1rem;
  font-weight: 600;
}

.requests__loading {
  /* font-size: 1.5rem; */
  font-weight: 900;
  padding: 0;
  margin: 0;
}

.requests__loading::after {
  /* Animatet three dots to simuate loading */
  content: "";
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: "....";
  }
}
