body{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #f5f5f5;
}

.enginePower__toHome{
    position: absolute;
    margin-left: -10px;
}

.enginePower__title{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #333;
}

.enginePower__input{
    display: flex;
    margin: 20px;
}

input[type=text]{
    width: 100%;
    box-sizing: border-box;
    font-size: medium;
}

.enginePower__inputBtn{
    height: 30px;
    min-width: 110px;
    font-size: large;
    cursor: pointer;
}

.enginePower__output{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
    background: white;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    font: 1.2rem sans-serif;

    min-height: 50px;
    line-height: 0.7;
}

/* First p of enginePower__outputError is bold */
.enginePower__outputError p:first-child{
    font-weight: 600;
}

.enginePower__statusLine--error{
    /* line with 10px width alaws on the left of side of parrent div */
    position: absolute;
    width: 10px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgb(193, 0, 0);
    border-radius: 10px 0 0 10px;
}

/* First  */

.enginePower__statusLine--success{
    position: absolute;
    width: 10px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgb(0, 144, 0);
    border-radius: 10px 0 0 10px;
}

.enginePowerValues{
    display: flex;
    align-items: center;
 }

.enginePowerValue{
    padding: 6px 10px ;
    margin: 3px;
    border-radius: 5px;
    background-color: orange;
}