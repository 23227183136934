.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.countdown--inactive {
  display: none;
}

.countdown__timer {
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown__text {
  color: #aaa;
}

.countdown__value {
  font-size: 0.8rem;
}
