.goodPrices__counts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0rem 0rem 0rem 0rem;
  padding: 0rem 0rem 0rem 0rem;
}

.goodPrices__queueCount {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(39, 39, 39, 0.797);
}

.goodPrices__goodPriceCount {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  color: rgb(39, 39, 39);
}

.goodPrices__goodPriceCount p {
  font-size: 3rem;
  font-weight: 700;
  color: cadetblue;
  padding: 0px;
  margin: 0rem;
}
